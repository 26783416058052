.scrollable {
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(84.44deg, #F73F4D 5.33%, #E3278D 94.63%), #000000;
        border-radius: 32px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(84.44deg, #A82B35FF 5.33%, #881755FF 94.63%), #000000;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}