.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 130px;
    left: 0px;
    opacity: 0.7;
}
