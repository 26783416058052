.legal {
    height: 100%;
}

ul {
    padding-left: 2rem;
}

li  {
    list-style-type: disc;
}